$simple-line-font-path        : "../fonts/";
// bower:scss
@import "bower_components/bourbon/app/assets/stylesheets/_bourbon.scss";
@import "bower_components/neat/app/assets/stylesheets/_neat.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import "bower_components/simple-line-icons/scss/simple-line-icons.scss";
@import "bower_components/slick-carousel/slick/slick.scss";
// endbower

// Fonts
@include font-face("RobotoBold", "../fonts/RobotoBold/RobotoBold", $file-formats: eot ttf woff);
@include font-face("RobotoLight", "../fonts/RobotoLight/RobotoLight", $file-formats: eot ttf woff);
@include font-face("RobotoMedium", "../fonts/RobotoMedium/RobotoMedium", $file-formats: eot ttf woff);
@include font-face("RobotoRegular", "../fonts/RobotoRegular/RobotoRegular", $file-formats: eot ttf woff);
@include font-face("RobotoThin", "../fonts/RobotoThin/RobotoThin", $file-formats: eot ttf woff);
@include font-face("Intro", "../fonts/Intro/Intro", $file-formats: eot ttf woff);

// Var color
$m_color: #da0927;
$s_color: #2b2e3b;
$white: #ffffff;
$black: #000000;

// Var background
$m_bg: #f7f7f7;



// Responsive
$mobile: "max-width: 479px";
$r_mobile: "min-width: 479px";
$tablet: "max-width: 769px";
$r_tablet: "min-width: 769px";
$tablet_ld: "max-width: 1025px";
$r_tablet_ld: "min-width: 1023px";
$laptop: "max-width: 1199px";
$laptop_hd: "max-width: 1281px";
$r_laptop_hd: "min-width: 1300px";
$laptop_hdd: "max-width: 1480px";
$r_laptop_hdd: "min-width: 1480px";

img{
  max-width: 100%;
}

// #preloader { 
//   position: fixed; 
//   left: 0; 
//   top: 0; 
//   z-index: 99999; 
//   width: 100%; 
//   height: 100%; 
//   overflow: visible; 
//   background: $m_bg url("../images/preloader.gif") no-repeat center center; 
// }

.loader-wrap{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: $white;
  z-index: 99999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 48%;
}

.line {
  animation: expand 1s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 1px;
  height: 25px;
}

.line:nth-child(1) {
  background: $m_color;
}

.line:nth-child(2) {
  animation-delay: 180ms;
  background: $m_color;
}

.line:nth-child(3) {
  animation-delay: 360ms;
  background: $m_color;
}

.line:nth-child(4) {
  animation-delay: 540ms;
  background: $m_color;
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}

// html {
//   overflow: hidden;
//   @media ($tablet_ld) {
//     overflow: visible;
//   }
// }

body {
  background: $s_color;
  font-family: 'RobotoRegular', Helvetica, Arial, sans-serif;
  color: $black;
  font-size: rem(16px);
}

section{
  display: block;
  position: relative;
  overflow: hidden;
  min-height: 650px;
  height: 650px;
}

section.-prev {
    opacity: 0;
}

._wrap{
  @include clearfix;
  @include outer-container();
  position: relative;
  @media ($laptop) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// .b_section_perspective {
//   position: relative;
//   transform-origin: 50% 100%;
//   transform: perspective(1600px) rotate3d(1, 0, 0, 0deg);
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }



// section.-current .b_section_perspective {
//   position: fixed;
//   @media ($tablet_ld) {
//     position: relative;
//   }
//   .nav_main{
//     height: 100px;
//     opacity: 1;
//   }
// }

.-nav .nav_main,
.nav_main{
  position: fixed;
  left: 0;
  background-color: $m_color;
  width: 100%;
  z-index: 90;
  height: 100px;
  overflow: hidden;
  transition: all 1s;
  top: 0;
  @media ($tablet_ld) {
    position: fixed;
    height: 60px;
    top: 0;
    left: 0;
    opacity: 1;
  } 
  @include row;
  ._logo{
    @include span-columns(3);
    @media ($tablet) {
      @include span-columns(6);
    }
    a{
      margin-top: 17px;
      display: inline-block;
      @media ($tablet_ld) {
        margin-top: 8px;
      } 
      img{
        max-height: 60px;
        @media ($tablet_ld) {
          max-height: 40px;
        } 
      }
    }
  }
  ._descr{
    @include span-columns(9);
    text-transform: uppercase;
    font-family: 'RobotoLight', Helvetica, Arial, sans-serif;
    color: $white;
    letter-spacing: 3px;
    text-align: center;
    font-size: rem(16px);
    line-height: 98px;
    @media ($tablet) {
      @include span-columns(6);
    }
    p{
      margin: 0;
      @media ($tablet) {
        display: none;
      }
    }
    @media ($tablet_ld) {
      font-size: rem(12px);
      padding-right: 60px;
      line-height: 58px;
    }    
    ._btn_nav{      
      .navtrigger{
        top: 37px;
        z-index: 10000;
        @media ($tablet_ld) {
          top: 18px;
          right: 10px;
        } 
      }
    }
  }
}

.nav_main{
  visibility: hidden;
  opacity: 0;
  height: 0;
  &.-show{
    visibility: visible;
    opacity: 1;
    height: 100px;
  }
  @media ($tablet_ld) {
    visibility: visible;
    opacity: 1;
    height: 60px;
  } 
}


.b_video{
  // padding-top: 60px;
  // @media ($mobile) {
  //   height: auto;
  //   min-height: 0;
  // }
  ._inner{
    ._logo_top{
      height: 300px;
      width: 100%;
      text-align: center;
      padding-top: 30vh;
      position: relative;
      z-index: 10;
      @media ($tablet_ld) {
        padding-top: 20%;
        margin: 0;
        img{
          max-width: 250px;
        }
      } 
      ._descr{
        font-family: 'RobotoRegular', Helvetica, Arial, sans-serif;
        font-weight: normal;
        font-size: rem(24px);
        text-transform: uppercase;
        color: $white;
        letter-spacing: 3px;
        margin-top: 20px;
        @media ($tablet_ld) {
          font-size: rem(15px);
        }
      }
    }
  }
  ._bot_nav{
    position: absolute;
    width: 100%;
    bottom: 0;
    color: $white;
    text-transform: uppercase;
    font-family: 'RobotoLight', Helvetica, Arial, sans-serif;
    font-size: rem(17px);
    letter-spacing: 4px;
    padding-bottom: 40px;
    @media ($laptop) {
     font-size: rem(13px);
    }
    @media ($tablet_ld) {
      font-size: rem(11px);
    }
    @include row;
    ._left{
      @include span-columns(3);
      @media ($tablet) {
        @include span-columns(12);
        text-align: center;
        margin-bottom: 10px;
      }
    }
    ._md{
      @include span-columns(6);
      padding: 0;
      margin: 0;
      list-style-type: none;
      text-align: center;
      @media ($tablet) {
        @include span-columns(12);
      }
      li{
        display: inline-block;
        font-family: 'RobotoBold', Helvetica, Arial, sans-serif;
        position: relative;
        margin-left: 30px;
        @media ($tablet_ld) {
          margin-left: 5px;
        }
        @media ($tablet) {
          @include span-columns(12);
          display: block;
          margin-bottom: 5px;
        }
        &:after{
          content: "";
          height: 70%;
          width: 1px;
          background-color: $white;
          left: -20px;
          top: 4px;
          position: absolute;
          z-index: 100;
          @media ($tablet_ld) {
            display: none;
          }
        }
        &:first-child{
          margin-left: 0;
          &:after{
            content: "";
            height: 0;
            width: 0;
            background-color: none;
          }
        }
      }
    }
    ._right{
      @include span-columns(3);
      text-align: right;
      @media ($tablet) {
        @include span-columns(12);
        text-align: center;
        margin-top: 5px;
      }
    }
  }
  .navtrigger{
    @media ($tablet_ld) {
      display: none;
    } 
  }
}
// video

.b_second{
  @media ($tablet) {
    height: auto;
    min-height: 0;
  }
  ._outher{
    @include row();
    color: $white;
    height: 100%;
    @media ($r_tablet) {
      @include row(table);
    }
    ._iner{
      font-family: 'Intro', Helvetica, Arial, sans-serif;
      font-size: rem(35px);
      position: relative;
      z-index: 5;
      @media ($laptop) {
        font-size: rem(25px);
      }
      @media ($tablet_ld) {
        line-height: 550px;
      } 
      @media ($tablet) {
        line-height: 250px;
      } 
    }
    ._slider_iner{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      ._item{
        height: 100% !important;
        z-index: 1;
        background-position: 50% 50%;
        background-size: cover;
        display: block;
      }
      .slick-list {
        height: 100%;
        .slick-track{
          height: 100%;
        }
      }
    }
    ._left{
      @include span-columns(4.5);
      text-align: right;
      background-image: url('../images/bg/2_1.jpg');
      padding-right: 50px;
      position: relative;
      @media ($laptop_hd) {
        @include span-columns(4);
      }
      @media ($tablet) {
        @include span-columns(12);
      }
    }
    ._md{
      @include span-columns(3);
      text-align: center;
      background-image: url('../images/bg/2_2.jpg');
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      @media ($laptop_hd) {
        @include span-columns(4);
      }
      @media ($tablet) {
        @include span-columns(12);
        border-left: none;
        border-right: none;
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;
      }
    }
    ._right{
      @include span-columns(4.5);
      background-image: url('../images/bg/2_3.jpg');     
      padding-left: 50px;
      @media ($laptop_hd) {
        @include span-columns(4);
      }
      @media ($tablet) {
        @include span-columns(12);
      }
    }
    ._right,
    ._md,
    ._left{
      position: relative;
      padding-top: 100px;
      background-position: 50% 50%;
      background-size: cover;
      display: table-cell;
      vertical-align: middle;
      filter: grayscale(100%);
      transition: all .4s;
      &:hover{
        filter: grayscale(0%);
        &:before{
          background-color: rgba($black,.49);
        }
      }
      @media ($tablet) {
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        height: 250px;
        line-height: 250px;
      }
      &:before{
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: rgba($black,.0);
        z-index: 2;
        transition: all .4s;
      }
    }
  }
}
// second

.b_third{
  height: auto;
  min-height: 0;
  .b_section_perspective{
    background-image: url('../images/bg/17.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding-bottom: 50px;
    &:before{
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      background-color: rgba($black,.49);
      z-index: 0;
    }
    ._title{
      padding-top: 140px;
      margin: 0;
      text-transform: uppercase;
      color: $white;
      font-family: 'Intro', Helvetica, Arial, sans-serif;
      padding-left: 60px;
      font-size: rem(45px);
      margin-bottom: 30px;
      font-weight: normal;
      @media ($laptop) {
        font-size: rem(30px);
        margin-bottom: 30px;
      }
      @media ($tablet_ld) {
        padding-top: 30px;
      } 
      @media ($mobile) {
        padding: 0;
        text-align: center;
        padding-top: 30px;
      }
    }
    ._descr{
      color: $white;
      padding-left: 60px;
      padding-right: 60px;
      ul{
        padding-left: 20px;
        li{
          margin-bottom: 10px;
        }
      }
      @media ($mobile) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  } 
}
// third

.b_fourth{
  background-color: $white;
  height: auto;
  min-height: 0;
  padding-bottom: 50px;
  @media ($tablet) {
    border-bottom: 3px solid $s_color;
  }
  ._title{
    padding-top: 140px;
    margin: 0;
    text-transform: uppercase;
    color: $black;
    font-family: 'Intro', Helvetica, Arial, sans-serif;
    padding-left: 60px;
    font-size: rem(45px);
    margin-bottom: 60px;
    font-weight: normal;
    @media ($laptop) {
      font-size: rem(30px);
      margin-bottom: 30px;
    }
    @media ($tablet_ld) {
      padding-top: 30px;
    } 
    @media ($mobile) {
      padding: 0;
      text-align: center;
      padding-top: 30px;
    }
  }
  ._logos{
    text-align: center;
    a{
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 20px;
      text-decoration: none;
      img{
        max-height: 45px;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray; 
        opacity: .7;
      }
      &:hover{
        img{
          filter: none;
          opacity: 1;
        }
      }
    }
  }
  .mouse_icon{
    border-color: $s_color;
    ._wheel{
      border-color: $s_color;
    }
  }
}

.b_fifth{
  background-color: $white;
  height: auto;
  min-height: 0;
  padding-bottom: 50px;
  ._title{
    padding-top: 140px;
    margin: 0;
    text-transform: uppercase;
    color: $black;
    font-family: 'Intro', Helvetica, Arial, sans-serif;
    padding-left: 60px;
    font-size: rem(45px);
    margin-bottom: 60px;
    font-weight: normal;
    @media ($laptop) {
      font-size: rem(30px);
      margin-bottom: 30px;
    }
    @media ($tablet_ld) {
      padding-top: 30px;
    }
    @media ($mobile) {
      padding: 0;
      text-align: center;
      padding-top: 30px;
    }
  }
  ._works{
    @include row;
    ._items{
      padding-left: 30px;
      padding-right: 30px;
      @media ($mobile) {
        padding: 0;
      }      
      ._item{
        @include span-columns(6);
        @include omega(2n);
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        margin-bottom: 30px;
        @media ($tablet_ld) {
          @include span-columns(12);
        }
        img{
          width: 100%;
          display: block;
        }
        a{
        text-decoration: none;
        &:hover{
          ._mask{
            background-color: rgba($s_color,.45);
            ._descr{
              visibility: visible;
              opacity: 1;
              transform: translateX(-50%) translateY(-50%) scale(1);
            }
          }
        }
      }
        ._mask{
          position: absolute;
          top: 0;
          left: 30px;
          right: 30px;
          bottom: 0;
          height: 100%;
          text-align: center;
          background-color: rgba($s_color,.0);
          color: $white;
          text-transform: uppercase;
          transition: all .6s ease;
          ._descr{
            visibility: hidden;
            position: absolute;
            width: 100%;
            text-align: center;
            transform: translateX(-50%) translateY(-50%) scale(.4);
            left: 50%;
            top: 50%;
            transition: all .6s ease;
            opacity: 0;
          }
          h2{
            font-family: 'RobotoBold', Helvetica, Arial, sans-serif;
            font-size: rem(17px);
            letter-spacing: 4px;
          }
          p{
            font-family: 'RobotoLight', Helvetica, Arial, sans-serif;
            font-size: rem(14px);
            letter-spacing: 4px;
          }
        }
      }      
    }     
  }
  .mouse_icon{
    border-color: $s_color;
    ._wheel{
      border-color: $s_color;
    }
  }
}
.-hidden{
  display: none !important;
}

.b_sixth{
  @media ($mobile) {
    height: auto;
    min-height: 0;
  }
  ._galery_item{
    display: none;
    &.-active{
      display: block;
    }
  }
  &.-current{
    ._title_wrap{
      margin-top: 100px;
    }
  }
  ._title_wrap{
    margin-top: 0px;
    height: 200px;
    padding-top: 100px;
    background-color: $s_color;
    transition: all 1s;
    @media ($tablet_ld) {
      height: 100px;
      padding-top: 0;
    }
    @media ($mobile) {
      height: auto;
    }
    ._title{
      margin: 0;
      text-transform: uppercase;
      color: $white;
      font-family: 'Intro', Helvetica, Arial, sans-serif;
      padding-left: 60px;
      font-size: rem(30px);
      margin-bottom: 60px;
      letter-spacing: 2px;
      font-weight: normal;
      line-height: 100px;
      @media ($laptop) {
        font-size: rem(25px);
      }
      @media ($mobile) {
        padding: 0;
        text-align: center;
        font-size: rem(20px);
        line-height: normal;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
      }
    }
  }
  ._slider{
    height: 100%;
    ._item{
      text-align: center;
      color: $white;
      background-size: cover;
      background-position: 50% 0%;
      position: relative;
      min-height: 75vh;
      @media ($mobile) {
        min-height: 0;
        height: auto;
      }
      &:after{
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: rgba($s_color,.5);
        z-index: 0;
      }
      ._descr{
        position: absolute;
        width: 100%;
        text-align: center;
        transform: translateX(-50%) translateY(-80%);
        left: 50%;
        top: 50%;
        z-index: 1;
        @media ($mobile) {
          position: relative;
          top: 0;
          left: 0;
          transform: translateX(0) translateY(0);
          padding-top: 30px;
          padding-bottom: 30px;
          padding-left: 10px;
          padding-right: 10px;
        }
        h3{
          margin: 0;
          font-family: 'RobotoThin', Helvetica, Arial, sans-serif;
          font-size: rem(48px);
          letter-spacing: 10px;
          text-transform: uppercase;
          @media ($mobile) {
            text-align: center;
            font-size: rem(20px);
          }
        }
        ._btn{
          color: $white;
          text-decoration: none;
          outline: none;
          text-transform: uppercase;
          font-family: 'RobotoLight', Helvetica, Arial, sans-serif;
          font-size: 14px;
          border: 2px solid $white;
          padding-left: 70px;
          padding-right: 70px;
          padding-top: 15px;
          padding-bottom: 15px;
          letter-spacing: 6px;
          margin-top: 50px;
          display: inline-block;
          transition: all .6s;
          @media ($mobile) {
            padding-left: 40px;
            padding-right: 40px;
          }
          &:hover{
            background-color: rgba($white,.3);
            color: $black;
          }
        }
      }
    }
  }
  ._thumbs{
    position: absolute;
    bottom: 0;
    width: 1px;
    z-index: 5;
    width: 100%;
    @media ($mobile) {
      position: relative;
      top: 0;
      display: block;
      bottom: auto;
    }
    ._item{
      cursor: pointer;
      outline: none;
      position: relative;
      height: 100px;
      overflow: hidden;
      background-color: $s_color;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all .7s;
        background-color: transparent;
        opacity: 0;
      }
      &.play-button{
        &:before{
          content: "\f04b";
        }
      }
      &:before{
        content: "\f00e";
        font-family: "FontAwesome";
        position: absolute;
        left: -10%;
        top: 50%;
        width: 100%;
        text-align: center;
        height: 20px;
        margin-top: -10px;
        color: $white;
        z-index: 10;
        font-size: rem(30px);
        transition: all .7s;
        opacity: 0;
      }
      &.slick-center,
      &:hover{
        &:after{          
          background-color: rgba($s_color,.8);
          opacity: 1;
        }
        &:before{
          opacity: 1;
          left: 0;
        }
      }
    }
  }
}

.b_seventh{
  height: auto;
  min-height: 0;
  &.-current{
    ._title_wrap{
      margin-top: 100px;
    }
    ._map_yandex {
      top: 200px;
    }
  }
  ._title_wrap{
    margin-top: 0px;
    height: 200px;
    padding-top: 100px;
    background-color: $s_color;
    transition: all 1s;
    @media ($tablet_ld) {
      height: auto;
      overflow: hidden;
      height: auto;
      padding-top: 0;
    }
    ._title{
      margin: 0;
      text-transform: uppercase;
      color: $white;
      font-family: 'Intro', Helvetica, Arial, sans-serif;
      padding-left: 60px;
      font-size: rem(30px);
      margin-bottom: 60px;
      letter-spacing: 2px;
      font-weight: normal;
      line-height: 100px;
      @media ($laptop) {
        font-size: rem(25px);
      }
      @media ($tablet_ld) {
        line-height: normal;
        padding: 0;
        margin: 0;
      }
    }
    ._row{
      @include row;
    }
    ._left{
      @include span-columns(3);
      @media ($tablet_ld) {
        @include span-columns(12);
        text-align: center;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
    ._md{
      @include span-columns(5);
      color: $white;
      text-align: center;
      line-height: 100px;
      font-family: 'RobotoLight', Helvetica, Arial, sans-serif;
      font-size: rem(15px);
      text-transform: uppercase;
      letter-spacing: 4px;
      @media ($tablet_ld) {
        @include span-columns(12);
        line-height: normal;
        padding: 0;
        margin: 0;
        text-align: center;
        margin-bottom: 5px;
      }
    }
    ._right{
      @include span-columns(4);
      color: $white;
      text-align: right;
      padding-right: 80px;
      line-height: 14px;
      // font-family: 'RobotoBold', Helvetica, Arial, sans-serif;
      font-size: rem(18px);
      letter-spacing: 1px;
      text-align: center;
      p{
        font-size: rem(15px);
        margin: 0;
        margin-top: 15px;
        margin-bottom: 10px;
      }
      a{
        color: $white;
        text-decoration: none;
        margin-bottom: 8px;
        display: inline-block;
        font-size: rem(17px);
        border-bottom: 1px dashed transparent;
        transition: all .3s;
        padding-bottom: 2px;
        &:hover{
          border-bottom: 1px dashed;
        }
      }
      @media ($tablet_ld) {
        @include span-columns(12);
        line-height: normal;
        padding: 0;
        margin: 0;
        text-align: center;
        margin-bottom: 10px;        
      }
    }
  }
  ._map_yandex {
    position: absolute;
    top: 200px;
    right: 0;
    left: 0;
    bottom: 0;
    transition: all 1s;
    @media ($tablet_ld) {
      position: relative;
      top: 0;
      z-index: 3;
      height: 300px;
    }
  }
}

.navtrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  position: absolute;
  margin-top: 0px;
  margin-right: 20px;
  display: block;
  right: 70px;
  top: 30px;
  z-index: 10000;
  i {
    background-color: $white;
    border-radius: 2px;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
  }
  i:nth-child(1) {
    -webkit-animation: outT 0.8s backwards;
    animation: outT 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }
  i:nth-child(2) {
    margin: 5px 0;
    -webkit-animation: outM 0.8s backwards;
    animation: outM 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }
  i:nth-child(3) {
    -webkit-animation: outBtm 0.8s backwards;
    animation: outBtm 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }
  &.-active i:nth-child(1) {
    -webkit-animation: inT 0.8s forwards;
    animation: inT 0.8s forwards;
  }
  &.-active i:nth-child(2) {
    -webkit-animation: inM 0.8s forwards;
    animation: inM 0.8s forwards;
  }
  &.-active i:nth-child(3) {
    -webkit-animation: inBtm 0.8s forwards;
    animation: inBtm 0.8s forwards;
  }
}



.b_header{
  visibility: hidden;
  width: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba($s_color,.3);
  transition: all 1s;  
  cursor: pointer;
  ._dots{
    width: 220px;
    overflow: hidden;
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 20px;
    background-color: rgba($m_color,1);
    height: 100%;
    transition: all 1s;  
    cursor: default;
    position: absolute;
    top: 0;
    min-width: 220px;
    right: -220px;
    text-align: right;
    @media ($laptop_hd) {
      right: auto;
      left: -220px;
      text-align: left;
    }
    ._logo_sm{
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    a{
      text-decoration: none;
      color: $white;
      font-family: 'RobotoMedium', Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      font-size: rem(20px);
      margin-bottom: 10px;
      position: relative;
      text-align: right;
      transition: all .3s;
      display: table;
      &:before {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: transparent;
        transition: all .3s ease;
        top: 100%;
        left: 0;
        transform: scale(0.5);
      }
      &:hover,
      &.-active{
        &:before{
          background-color: $white;
          transform: scale(1);
        }
      }
    }
  }
  &.-show{
    visibility: visible;
    opacity: 1;
    z-index: 200;
    ._dots{
      right: 0;
      @media ($laptop_hd) {
        right: auto;
        left: 0;
      }     
    }
  }
}

.mouse_icon{
  border: 2px solid $white;
  border-radius: 16px;
  height: 40px;
  width: 26px;
  display: block;
  z-index: 10;
  opacity: 0.7;
  position: absolute;
  bottom: 100px;
  left: 50%;
  margin-left: -14px;
  cursor: pointer;
  @media ($tablet_ld) {
    display: none;
  }
  ._wheel {
    -webkit-animation-name: drop;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    -webkit-animation-name: drop;
    animation-name: drop;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    position: relative;
    border-radius: 10px;
    border: 2px solid $white;
    width: 6px;
    height: 10px;
    top: 2px;
    margin-left: auto;
    margin-right: auto;
  }
}

#video { 
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translateX(-50%) translateY(-50%);
  background-image: url('../images/bg/1.jpg');
  background-size: cover;
  overflow: hidden;
}

// .tv {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 0;

//   width: 100%;
//   height: 100%;

//   overflow: hidden;
  
//   .screen {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: 0;

//     margin: auto;

//     opacity: 0;
//     transition: opacity .5s;

//     &.active {
//       opacity: 1;
//     }
//   }
// }

// #video{
//  position: relative;
//  background: transparent;
// }


#video-container {
  position: absolute;
}
#video-container {
  top:0%;
  left:0%;
  height:100%;
  width:100%;
  overflow: hidden;
}
video {
  position:absolute;
  z-index:0;
}
video.fillWidth {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translateX(-50%) translateY(-50%);
  background-image: url('../images/bg/1.jpg');
  background-size: cover;
  overflow: hidden;
}
.player,
.player1 {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translateX(-50%) translateY(-50%);
  background-image: url('../images/bg/1.jpg');
  background-size: cover;
  overflow: hidden;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes fadeInD {
  0% {
    opacity: 0;
    margin-top: -50px;
  }
  20% {
    opacity: 0;
    margin-top: -40px;
  }
  40% {
    opacity: 0.3;
    margin-top: -30px;
  }
  60% {
    opacity: 0.5;
    margin-top: -20px;
  }
  80% {
    opacity: 0.9;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes fadeInD {
  0% {
    opacity: 0;
    margin-top: -50px
  }
  20% {
    opacity: 0;
    margin-top: -40px
  }
  40% {
    opacity: 0.3;
    margin-top: -30px
  }
  60% {
    opacity: 0.5;
    margin-top: -20px
  }
  80% {
    opacity: 0.9;
    margin-top: -10px
  }
  100% {
    opacity: 1;
    margin-top: 0px
  }
}

@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}
@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}